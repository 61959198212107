import { AddressEnvMap, SupportedChainId } from "./chains.base";
export const SUPPORTED_CHAIN_IDS_MAP: AddressEnvMap = {
  // [SupportedChainId.ARB_TEST]: SupportedChainId.ARB_TEST,
  // [SupportedChainId.BSCTEST]: SupportedChainId.BSCTEST,
  // [SupportedChainId.ARBITRUM]: SupportedChainId.ARBITRUM,
  [SupportedChainId.BSCMAINNET]: SupportedChainId.BSCMAINNET,
};

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  // SupportedChainId.ARB_TEST,
  // SupportedChainId.BSCTEST,
  // SupportedChainId.ARBITRUM,
  SupportedChainId.BSCMAINNET,
];
